import React from "react"
import { navigate } from "gatsby"
import Layout from "../components/global/layout"
import Container from "../components/container/container"
import RegisterForm from "../components/register/register-form"
import SimpleIntro from "../components/global/simple-intro"

const RegisterPage = ({ component: Component, location, ...rest }) => {
  const handleRegistered = () => {
    navigate("/login", {
      state: {
        generalMsg:
          "You have successfully registered. Please login to continue.",
        msgType: "good",
      },
    })
  }

  return (
    <Layout>
      <Container gutters width={"xsmall"}>
        <SimpleIntro title={"Register"} />
        <RegisterForm handleRegistered={handleRegistered} />
      </Container>
    </Layout>
  )
}
export default RegisterPage
